import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  const educationDetails = [
    {yearRange: "2015 - 2020", title: "Ingeniero Administrador en Sistemas", place: "Universidad Autónoma de Nuevo León", desc: ""},
    {yearRange: "2021", title: "Big Data y Estrategia de Negocios", place: "Universidad del Valle de México", desc: ""},
    {yearRange: "2015 - 2016", title: "Tecnico Desarrollador de Software", place: "Universidad Autónoma de Nuevo León", desc: ""},
  ];

  const experienceDetails = [
    { yearRange: "2021 - Actual", title: "Salesforce Developer", place: "Banregio",
      desc: "Actualmente formo parte del equipo de desarrollo CRM en donde abarca plataformas como: Salesforce Sales Cloud, " +
        "Salesforce Service Cloud y Salesforce Field Service. La principal función es realizar desarrollos " +
        "solicitados por las áreas tanto de Banregio así como de Hey Banco.",
    },
    { yearRange: "2023 - 2024", title: "Instructor Salesforce", place: "Emtech",
      desc: "Parte del equipo de docentes dentro de Emtech Institute donde mi funcion principal era realizar tutorías " +
        "grupales con los alumnos en donde se refuerzan los temas expuestos del módulo y se realizan prácticas para " +
        "aplicar lo aprendido. Además, se realizan sesiones uno a uno con alumnos que lo requieran, cuyo objetivo era " +
        "apoyar a los alumnos y lograr obtener su certificación en la tecnología Salesforce."
    },
    { yearRange: "2019 - 2021", title: "Salesforce Developer",place: "Clínicas del Azúcar",
      desc: "Mi función principal en Clínicas del Azúcar es administrar todo el CRM de Salesforce Sales, Service y Marketing Cloud " +
        "así como el encargado de desarrollo e implementación de nuevas funcionalidades requeridas por parte de la empresa.",
    },
    { yearRange: "2017 - 2019", title: "Soporte", place: "Delaware Software",
      desc: "Tuve la oportunidad de dar apoyo en Soporte al sistema de información utilizado en " + 
      "el Centro Universitario Contra el Cáncer, dentro de las instalaciones del Hospital Universitario.",
    },
  ];

  const skills = [
    { name: "Salesforce Certified IA Associate", id: "ID: 3694378"},
    { name: "Salesforce Certified Associate", id: "ID: 3277718"},
    { name: "Salesforce Certified Advanced Administrator (SCAA)", id: "ID: 2401843"},
    { name: "Salesforce Certified Platform App Builder", id: "ID: 23009174"},
    { name: "Salesforce Certified Platform Developer I", id: "ID: 22926275"},
    { name: "Salesforce Certified Administrator (SCA)", id: "ID: 22791562"},
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2 text-white">CV</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Un resumen de mi CV
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">Educación</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">Experiencia</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">Certificaciones</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end text-muted">{skill.id}</span>
                </p>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href=""
            
          >
            Descargar CV
          </a>
        </p>
      </div>
    </section>
  );
};
//download
export default Resume;
